<script setup >
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import {
  XMarkIcon,
} from '@heroicons/vue/24/outline'
import Header from "./Header.vue";
import SlideMenu from "./SlideMenu";
import {MainLayoutStore} from "./main-layout-store";
import {PageLoadingStore} from "~/common-store/page-loading-store";
import Breadcrumbs from "~/components/breadcrumbs/Breadcrumbs";
import {GlobalPlatformInfoStore} from "~/store/global-platform-info-store";

const pageLoadingStore = PageLoadingStore()
const mainLayoutStore = MainLayoutStore();
const globalPlatformInfoStore = GlobalPlatformInfoStore();

useHead({
  titleTemplate: globalPlatformInfoStore.platformLogoFullText,
})

</script>

<template>
  <div>
    <Spin
        v-model="pageLoadingStore.loading"
        type="lottiePhoneAnimate"
    >
      <TransitionRoot as="template" :show="mainLayoutStore.sidebarOpen">
        <Dialog as="div" class="relative z-50 lg:hidden" @close="mainLayoutStore.sidebarOpen = false">
          <TransitionChild
              as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0"
              enter-to="opacity-100" leave="transition-opacity ease-linear duration-300"
              leave-from="opacity-100" leave-to="opacity-0">
            <div class="fixed inset-0 bg-gray-900/80"/>
          </TransitionChild>

          <div class="fixed inset-0 flex">
            <TransitionChild
                as="template" enter="transition ease-in-out duration-300 transform"
                enter-from="-translate-x-full" enter-to="translate-x-0"
                leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0"
                leave-to="-translate-x-full">
              <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
                <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0"
                                 enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100"
                                 leave-to="opacity-0">
                  <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" class="-m-2.5 p-2.5" @click="mainLayoutStore.sidebarOpen = false">
                      <span class="sr-only">Close sidebar</span>
                      <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true"/>
                    </button>
                  </div>
                </TransitionChild>

                <SlideMenu/>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </TransitionRoot>

      <!-- Static sidebar for desktop -->
      <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div class="flex grow flex-col overflow-y-auto border-r border-gray-200 bg-white gap-y-5">
          <SlideMenu/>
        </div>

      </div>

      <div class="lg:pl-72">
        <Header/>

        <main class="pt-[40px]">

          <Transition mode="out-in">
            <div class="px-4 sm:px-6 lg:px-8">
              <div
                  class="mb-[10px]"
                  v-if="mainLayoutStore.breadcrumbs.length>0"
              >
                <Breadcrumbs :items="mainLayoutStore.breadcrumbs"/>
              </div>

              <slot/>
            </div>
          </Transition>
        </main>
      </div>
    </Spin>
  </div>
</template>
